$(function () {
  /*----------------------------------------YDCOZA----------------------------------------*/
  /*    Fade in Flex slider images after page load */
  /*--------------------------------------------------------------------------------------*/

    $('.flexslider').fadeIn(50);

  /*----------------------------------------YDCOZA----------------------------------------*/
  /*    Flexslider */
  /*--------------------------------------------------------------------------------------*/

    $('.flexslider').flexslider({
      animation: "fade",
      slideshow: true,
      slideshowSpeed: 6000,
      animationDuration: 900,
      // directionNav: false,
      controlNav: false,
      prevText: "<i class='fa fa-chevron-left'></i>",
      nextText: "<i class='fa fa-chevron-right'></i>",
      pauseOnHover: true
    });
});